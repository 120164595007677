var site = site || {};
(function($) {
  Drupal.behaviors.LBO = {
    attach: function(context, settings) {
      site.positionSiteHeaderCntr = true;
      site.positionSiteHeader = function() {
        var siteHeader = $(".site-header", context),
            siteWideBanner = $(".site-wide-banner", context),
            mediumBreakpointWidth = parseInt(Unison.fetch.all().medium),
            windowWidth = $(window).width(),
            siteHeaderSticky = $(".js-site-header-sticky", context),
            bannerHeight = siteWideBanner.height();
        if (siteHeader.length > 0) {
          if (siteHeader.prev(".site-wide-banner").length === 0) {
            siteHeader.before(siteWideBanner);
          }
          if (windowWidth < mediumBreakpointWidth) {
            siteHeader.css("margin-top", bannerHeight);
            siteHeaderSticky.css("top", bannerHeight);
            site.positionSiteHeaderCntr = false;
          } else {
            if (!site.positionSiteHeaderCntr) {
              bannerHeight = siteWideBanner.height();
              siteHeader.css("margin-top", 0);
              siteHeaderSticky.css("top", 0);
              site.positionSiteHeaderCntr = true;
            }
          }
        }
      };

      //
      // On cart update, strip &nbsp; characters such that copy wraps within the container
      //
      $(window).on("checkout:panel:displayed", function() {
        decodeHtmlEntities($("#lbo-message-container .lbo-msg", context));
      });

      function insertLinkBasedOffer() {
        var $lboPCHeaderCont = $(".lbo-pc-header-container", context),
            templateResult = $lboPCHeaderCont.html(),
            $siteHeader = $(".site-header", context),
            $lboContainer = $("#lbo-message-container", context),
            $target = $siteHeader;
        if (!site.client.isMobile) {
          if (!$lboContainer.length) {
            if ($siteHeader.prev(".site-wide-banner").length) {
              $target = $siteHeader.prev(".site-wide-banner");
            }
            $target.before(templateResult);
          }
        } else {
          var $siteWideBanner = $(".site-wide-banner", context);
          if (!$lboContainer.length) {
            $siteWideBanner.prepend(templateResult);
          }
          $siteWideBanner.children(".lbo.site-banner").siblings().addClass("site-wide-banner-elements");
          site.positionSiteHeader();
          $(window).on("resize", _.throttle(function() {
            site.positionSiteHeader();
          }, 250));
        }
      }

      function displayLinkBasedOffers(offer_code) {
        generic.jsonrpc.fetch({
          method: "offer.linkedOfferState",
          params: [offer_code],
          onSuccess: function(r) {
            var offer = r.getValue();
            var isActive = (offer.linked_offer !== null && offer.linked_offer.length !== 0);
            if (isActive) {
              var htmlStr = offer.linked_offer.cms_content;
              $(htmlStr).appendTo("body");
              var $confirmMessage = $(".lbo-confirm-message", context);
              $confirmMessage.html(offer.linked_offer.offer_message);
              decodeHtmlEntities($confirmMessage);
              insertLinkBasedOffer();

              $(".lbo.site-banner .icon-close", context).bind("click", function(e) {
                var $siteWideBanner = $(".site-wide-banner", context);
                $siteWideBanner.find(".site-wide-banner-elements").removeClass("site-wide-banner-elements");
                $siteWideBanner.find(".lbo.site-banner").remove();
                $siteWideBanner.removeClass("lbo-container");
              });
            }
          },
          onFailure: function() {}
        });
      } // End -- displayLinkBasedOffers

      var decodeHtmlEntities = function(elem) {
        if (elem.length) {
          var formattedStr = elem.html().replace(/&nbsp;/g, " ");
          elem.html(formattedStr);
        }
      };

      if (typeof $.cookie === "function") {
        if ($.cookie("offer_info") !== "" && $.cookie("offer_info") !== null) {
          // Landing page utilizes cookie based offer code
          var offer_code = $.cookie("offer_info").split(":")[1];
          displayLinkBasedOffers( offer_code );
        }
      } else {
        var $lboMessageContainer = $("#lbo-message-container", context);
        if ($lboMessageContainer.length) {
          // Hide default lbo error message as this has been styled now
          // $(".single-message").addClass("hidden");
        }
      }
      decodeHtmlEntities($(".lbo-msg", context));
    }
  };
})(jQuery);
